<!--
// nuxt-ui/layouts/teamPage.vue
-->
<script setup lang="ts">
import type { Season } from "~/src/season-cluster/domain/models/01.Season";
import TheHeader from "~/nuxt-ui/components/layout/TheHeader.vue";
import TheFooter from "~/nuxt-ui/components/layout/TheFooter.vue";
import { useTeamAndPlayerStore } from "~/nuxt-ui/stores/teamAndPlayerStore";
import { useCompetitionStore } from "~/nuxt-ui/stores/competitionStore";
import { useWebappStore } from "~/nuxt-ui/stores/webappStore";
import { useTeams } from "~/nuxt-ui/Shared/composables/teams";
import { usePlayers } from "~/nuxt-ui/Shared/composables/players";
import regionUiService from "~/src/region/infrastructure/ui-services/regionUiService";
import { useWebContext } from "~/nuxt-ui/Shared/composables/webContext";

const toolbarVisible = ref(true);
const eventListeners = ["scroll", "resize"];
onMounted(() => {
  eventListeners.forEach(event => {
    window.addEventListener(event, () => {
      toolbarVisible.value = !(window.scrollY > 28 || window.innerWidth < 1200);
    });
  });
});

const route = useRoute();
const { region, loadState } = useCompetitionStore();

const reloadCompetitionStore = async (season: Season) => {
  const webappStore = useWebappStore();
  const { webContext } = useWebContext();
  const { getRegions } = regionUiService();
  const regions = await getRegions();
  const activeRegion = regions.find(
    r => r.competitionId[webContext] && r.competitionId[webContext]?.includes(season.competitionId)
  );

  await loadState(activeRegion);
  webappStore.setActiveRegion();
};

if (!region) {
  const teamAndPlayerStore = useTeamAndPlayerStore();

  if (route.params.team) {
    const { findTeamCurrentOrRecentSeason, useFetchParamsTeamSeasons } = useTeams();

    teamAndPlayerStore.teamId = Number(route.params.team);
    const { data } = await useFetch(useFetchParamsTeamSeasons(teamAndPlayerStore.teamId));
    teamAndPlayerStore.teamSeasonsPlayed = data.value ? data.value : undefined;
    teamAndPlayerStore.teamLastSeasonPlayed = teamAndPlayerStore.teamSeasonsPlayed
      ? findTeamCurrentOrRecentSeason(teamAndPlayerStore.teamSeasonsPlayed)
      : undefined;

    if (teamAndPlayerStore.teamLastSeasonPlayed)
      await reloadCompetitionStore(teamAndPlayerStore.teamLastSeasonPlayed);
  } else if (route.params.playerId) {
    const { useFetchParamsPlayerSeasons, findLastSeasonPlayedByPlayer } = usePlayers();

    teamAndPlayerStore.playerId = Number(route.params.playerId);
    const { data } = await useFetch(useFetchParamsPlayerSeasons(teamAndPlayerStore.playerId));
    teamAndPlayerStore.playerSeasonsPlayed = data.value ? data.value : undefined;
    teamAndPlayerStore.playerLastSeasonPlayed = teamAndPlayerStore.playerSeasonsPlayed
      ? findLastSeasonPlayedByPlayer(teamAndPlayerStore.playerSeasonsPlayed.seasons)
      : undefined;

    if (teamAndPlayerStore.playerLastSeasonPlayed)
      await reloadCompetitionStore(teamAndPlayerStore.playerLastSeasonPlayed);
  } else {
    const webappStore = useWebappStore();
    const { getRegions } = regionUiService();
    const regions = await getRegions();
    const activeRegion = regions.find(r => r.id === 1);

    await loadState(activeRegion);
    webappStore.setActiveRegion();
  }
}
</script>

<template>
  <the-header :toolbar-visible="toolbarVisible"></the-header>
  <main class="main" :class="toolbarVisible ? 'mt-0' : 'mt-[130px] lg:mt-[152px]'">
    <slot />
  </main>
  <the-footer></the-footer>
</template>

<style scoped>
.main {
  min-height: calc(theme(height.screen) - 20vh);
}
</style>
